<template>
  <div class="page_content_wrap">
    <div class="let_gets_to_know_you_wrap py-5">
      <div class="container">
        <div class="let_gets_to_know_you_box" v-if="!isLoading && getUser">
          <h2 class="text-dark mb-4">
            OK, {{ getUser.name }}! Let's get to know you.
          </h2>
          <form action="" @submit.prevent="submitWizard">
            <div class="form-group mb-3">
              <label for="retirement_concern" class="text-dark"
                >What would you say is your primary retirement concern?</label
              >
              <div
                class="
                  checkbox_btn_with_txt
                  btn-group-toggle
                  retirement_concern
                "
                data-toggle="buttons"
                v-if="concerns.length"
              >
                <div class="row mb-3">
                  <div class="col-12 col-md-4 mt-3">
                    <label
                      class="btn btn-outline-primary"
                      :class="{
                        active:
                          survey.my_top_retirement_concerns ==
                          concerns[0].option,
                      }"
                    >
                      <input
                        type="radio"
                        name="my_top_retirement_concerns"
                        :value="concerns[0].option"
                        v-model="survey.my_top_retirement_concerns"
                      />
                      <i :class="concerns[0].icon"></i>
                      <span>{{ concerns[0].option }}</span>
                    </label>
                  </div>
                  <div class="col-12 col-md-4 mt-3">
                    <label
                      class="btn btn-outline-primary"
                      :class="{
                        active:
                          survey.my_top_retirement_concerns ==
                          concerns[1].option,
                      }"
                    >
                      <input
                        type="radio"
                        name="my_top_retirement_concerns"
                        :value="concerns[1].option"
                        v-model="survey.my_top_retirement_concerns"
                      />
                      <i :class="concerns[1].icon"></i>
                      <span>{{ concerns[1].option }}</span>
                    </label>
                  </div>
                  <div class="col-12 col-md-4 mt-3">
                    <label
                      class="btn btn-outline-primary"
                      :class="{
                        active:
                          survey.my_top_retirement_concerns ==
                          concerns[2].option,
                      }"
                    >
                      <input
                        type="radio"
                        name="my_top_retirement_concerns"
                        :value="concerns[2].option"
                        v-model="survey.my_top_retirement_concerns"
                      />
                      <i :class="concerns[2].icon"></i>
                      <span>{{ concerns[2].option }}</span>
                    </label>
                  </div>

                  <div class="col-12 col-md-4 mt-3">
                    <label
                      class="btn btn-outline-primary"
                      :class="{
                        active:
                          survey.my_top_retirement_concerns ==
                          concerns[3].option,
                      }"
                    >
                      <input
                        type="radio"
                        name="my_top_retirement_concerns"
                        :value="concerns[3].option"
                        v-model="survey.my_top_retirement_concerns"
                      />
                      <i :class="concerns[3].icon"></i>
                      <span>{{ concerns[3].option }}</span>
                    </label>
                  </div>
                  <div class="col-12 col-md-4 mt-3">
                    <label
                      class="btn btn-outline-primary"
                      :class="{
                        active:
                          survey.my_top_retirement_concerns ==
                          concerns[4].option,
                      }"
                    >
                      <input
                        type="radio"
                        name="my_top_retirement_concerns"
                        :value="concerns[4].option"
                        v-model="survey.my_top_retirement_concerns"
                      />
                      <i :class="concerns[4].icon"></i>
                      <span>{{ concerns[4].option }}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="btn_continue_wrap">
              <button
                type="submit"
                class="btn btn-primary btn-lg btn-block mt-4"
                :disabled="isSubmitting || survey.my_top_retirement_concerns == null"
                :class="{ 'btn-load': isSubmitting }"
              >
                CONTINUE <i class="fas fa-arrow-right pl-1"></i>
              </button>
            </div>
          </form>
        </div>
        <div class="loader" v-if="isLoading || !getUser"></div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { getContact, updateContact } from "@/utils/firebase";
import QUESTIONS from "@/data/personal-details-questions";
import { parse } from "query-string";

export default {
  name: "Screen4",
  data() {
    return {
      concerns: QUESTIONS.my_top_retirement_concerns.options,
      isLoading: true,
      isSubmitting: false,
      errors: [],
    };
  },
  mounted() {
    this.isLoading = true;
    const contactId = "kyle@dataskeptic.com"
    getContact(contactId)
      .then((response) => {
        this.isLoading = false;
        let prop = response.data.properties;
        console.log(response.data.properties);
        this.SET_SURVEY(prop);
      })
      .catch((error) => {
        this.isLoading = false;
        console.log("error");
        console.log(error);
      });
  },
  computed: {
    ...mapGetters({
      getUserId: "getUserId",
      getUser: "getUser",
    }),
    ...mapGetters("wizard", {
      survey: "getSurvey",
    }),
  },
  methods: {
    ...mapActions("wizard", {
      updateProgress: "updateProgress",
    }),
    ...mapMutations("wizard", ["SET_SURVEY", "UPDATE_SURVEY_FIELD"]),
    checkForm() {
      this.errors = [];

      if (this.survey.my_top_retirement_concerns == "") {
        this.errors.push("Concern required.");
      }

      //   if (this.survey.are_you_retired == '') {
      //     this.errors.push("Work status required.");
      //   }

      if (this.errors.length) {
        return false;
      } else {
        return true;
      }
    },
    submitWizard(e) {
      let values = {
        marital_status: this.survey.marital_status,
        are_you_retired: this.survey.are_you_retired,
        retirement_age: this.survey.retirement_age,
        age: this.survey.age,
        my_top_retirement_concerns: this.survey.my_top_retirement_concerns,
        industry: this.survey.industry,
        personal_finances_attitude: this.survey.personal_finances_attitude,
      };

      if (this.checkForm()) {
        this.isSubmitting = true;

        // updateContact(values)
        //   .then((response) => {
            const queryParams = parse(location.search);

            if (queryParams["from-review"]) {
              this.$router.push({ name: "risk.review" });
            } else {
              let userObj = {
                riskLink: "risk.assessmentOverview",
              };
              this.updateProgress({ step: 1, data: userObj });
              this.$router.push({ name: "risk.assessmentOverview" });
            }
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //     this.isSubmitting = false;
        //     alert("Something went wrong!");
        //   });
      }

      e.preventDefault();
    },
  },
};
</script>
